

@font-face {
	font-family: 'AvenirNextCyr-Regular';
	src: url('../fonts/anc/avenirnextcyr-regular.eot'); /* IE 9 Compatibility Mode */
	src: url('../fonts/anc/avenirnextcyr-regular.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
		url('../fonts/anc/avenirnextcyr-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/anc/avenirnextcyr-regular.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
		url('../fonts/anc/avenirnextcyr-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/anc/avenirnextcyr-regular.svg#avenirnextcyr-regular') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: "Oswald-Extra-Light";
  src: url('../fonts/oswald/Oswaldextralight.woff2') format('woff2'), 
      url('../fonts/oswald/Oswaldextralight.woff') format('woff'), 
      url('../fonts/oswald/Oswaldextralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
	font-family: 'AvenirNextCyr-Demi';
	src: url('AvenirNextCyr-Demi.eot');
	src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
		url('../fonts/anc/AvenirNextCyr-Demi.woff') format('woff'),
		url('../fonts/anc/AvenirNextCyr-Demi.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'AvenirNextCyr-Bold';
	src: url('AvenirNextCyr-Bold.eot');
	src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
		url('../fonts/anc/AvenirNextCyr-Bold.woff') format('woff'),
		url('../fonts/anc/AvenirNextCyr-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

.scope-style {
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  li {
    list-style: none;
  }

  span, a, p, td {
    font-family: 'AvenirNextCyr-Regular';
    font-size: 16px;
  }

  a {
    text-decoration: none;
  }
  
  img {
    height: auto;
    max-width: 100%;
  }
  
  button {
    border: none;
    outline: none;
    cursor: pointer;
  }


.container {
  width: 1326px;
  margin: 0 auto;

  &_menu {
    width: 1195px;
  }

  &_popular-typography {
    width: 1243px;
  }

  &__services-in-moscow {
    width: 1213px;
  }

  &__price-list {
    width: 1350px;
  }

  &_advertising-commercial-printing {
    width: 1094px;
  }

  &_payment-methods {
    width: 1020px;
  }

  &_payment-methods {
    width: 1224px;
  }
  &_feedback {
    width: 1000px;
  }
}

.top-nav {
  background-color: #3c0034;
  padding: 7px 0;


  &-sub {
   position: absolute;
   z-index: 555;
   display: flex;
   background: #fff;
   padding: 20px;
   top: 24px;
   visibility: hidden;
   opacity: 0;
   transition: .3 ease;
   &::after {
    content: "";
    position: absolute;
    top: -17px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
   }

   &__list {
     padding-right: 30px;
   }

   &__item {
     margin-top: 10px;

     &:first-child {
       margin-top: 0;
     }
   }

   &__link {
     color: #000;
     padding: 5px 0;
     transition: .3s;
     &:hover {
      color: #c293bf;
     }
   }
  }

  &__item {
    position: relative;

    &:hover {
      
      .top-nav-sub {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;

    &_purple {
      color: #3c0034;
    }
  }
}

.logo {
  align-self: center;
  position: relative;
  top: 3px;
}

.center-nav {
  background: #fff;
  padding-top: 7px;
  padding-bottom: 14px;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
}

.center-nav-button-group {
  display: flex;
  flex-direction: column;
  padding-left: 42px;
  justify-content: space-between;
  padding-bottom: 9px;
  padding-top: 5px;

  &__btn {
    color: #3c0034;
    font-size: 12px;
    font-weight: 500;
    width: 125px;
    height: 19px;
    border-radius: 9px;
    background-color: #c293bf;
    white-space: nowrap;
    letter-spacing: -0.5px;
    &_w160 {
      width: 160px;
    }
  }
}

.bottom-nav {
  padding: 2px 0;
  background-color: #c293bf;
}

.center-nav-phone-group {
  display: flex;
  padding-left: 42px;
  padding-top: 6px;
  &_mpl {
    padding-left: 30px;
  }

  &__phone {
    padding-left: 6px;
    display: flex;
    flex-direction: column;

   

    &_mail-box {
      padding-top: 3px;
    }
  }

  &__number {
    color: #000000;
    font-weight: 700;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: .7px;

    &_fwn {
      font-weight: normal;
    }
  }

  &__back-call {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-decoration: underline;
    letter-spacing: -0.76px;
    text-align: left;
  }
}

.center-nav-metro {
  padding-left: 54px;
  &__content {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
  }

  &__list {
    
  }

  &__item {
    display: flex;

    &:not(:first-child) {
      padding-top: 10px;
    }
  }

  &__name {
    color: #000000;
    font-family: "Avenir Next Cyr";
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;

    &_fwn {
      font-weight: normal;
    }
  }

  &__address {
    font-size: 12px;
    text-decoration: underline;
    color: #000;
  }
}

.home-page-slider {
  &__item {
    height: 341px;

  }

  &__img {
    height: 100%;
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-dots {
    bottom: 25px;
  }
  .slick-dots {



    li {
      margin: 0;
      &.slick-active {

        button {
          background-color: #3c0034;
        }
      }

      button {
        /* Style for "Эллипс 1 к" */
        width: 8px;
        height: 8px;
        background-color: #ffffff;
        border-radius: 50%;
        margin-right: 0;
        &:before {
          content: '';
        }
      }
    }
  }


}

.popular-typography {
  padding-top: 42px;
  &__title {
    text-align: center;
    &-text {
      color: #3c0034;
      font-family: "Oswald-Extra-Light";
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__slider {
    display: flex;
    justify-content: center;
  }

  &__body {
    margin-top: 20px;
    background-color: #efece6;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
  }

  &__ps {
    margin-top: 40px;
    text-align: center;
    &-h1 {
      font-family: "Oswald-Extra-Light";  
      font-size: 24px;
      font-weight: bold;
      color: #c293bf;

      &_purple {
        color: #3c0034;
      }
    }

    &-h2 {
      font-family: "Oswald-Extra-Light";  
      color: #d7b7d5;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.popular-typography-item {
  width: 16.6666%;
  padding-top: 30px;
  &__image {
    text-align: center;
    height: 126px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    img {
      height: 100%;
      max-width: unset;
    }
  }

  &__name {
    text-align: center;
    margin-top: 14px;
    &-text {
      font-family: 'AvenirNextCyr-Demi';
      text-transform: uppercase;
      color: #3c0034;
    }
  }
}

.advantage-typography {
  padding-top: 74px;
  padding-bottom: 50px;
  &__title {
    text-align: center;
    &-text {
      color: #3c0034;
      font-family: Oswald-Extra-Light;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
    
  }

  &__body {
    display: flex;
    padding-top: 40px;
    
  }
}

.advantage-typography-item {
  width: 14.28%;

  &__icon {
    text-align: center;
    height: 84px;
  }

  &__body {
    text-align: center;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__text {
    font-family:"AvenirNextCyr-Demi";
    color: #040707;
    font-size: 14px;
    font-weight: bold;
    line-height: 12px;
  }
}

.our-clients {
  padding-bottom: 33px;
  &__inner {
    padding: 0 60px;
  }
}

.our-clients-slider {
  display: flex;
  padding-top: 20px;
  &__item {
    width: 30%;
  }

  // .slick-list,
  // .slick-track {
  //   width: 100% !important;
  // }

  .slick-track {
    display: flex;
  }

  &__img {
    display: flex;
    align-items: center;
    height: 100px;
    img {
      margin: 0 auto;
    }
  }

  &__title {
    text-align: center;
    &-text {
      font-family: "AvenirNextCyr-Demi";
      color: #040707;
      font-weight: 700;
      line-height: 12px;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.our-clients {
  padding-top: 20px;
}

#our-clients__next-arrow,
#our-clients__prev-arrow {
  background-image: url('../img/icons/our-clients-arrow.png');
  width: 28px;
  height: 45px;
  background-color: transparent;
  position: absolute;
  top:35%;
  
}

#our-clients__next-arrow {
  right: -40px;
  transform: rotate(180deg);
}

#our-clients__prev-arrow {
  left: -40px;
}

.reviews {
  padding-top: 38px;

  &-list {
    display: flex;
    padding-top: 40px;
  }

  &-item {
    display: flex;
    width: 25%;

    &__image {
      width: 59%;
      padding-right: 15px;
    }

    &__name {
      &-text {
        color: #3c0034;
        font-size: 12px;
        font-weight: bold;
        font-style: italic;
      }
    }

    &__position {
      color: #3c0034;
      font-size: 12px;
      font-style: italic;
    }

    &__body {
      width: 52%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__text {
      font-size: 12px;
      font-style: italic;
      font-weight: bold;
      color: #140f0c;
      margin: 10px 0;
    }

    &__view {
      color: #3c0034;
      font-size: 12px;
      font-weight: bold;
      text-decoration: underline;
      position: relative;
      &::after {
        content: '';
        background-image: url('../img/icons/youtube.png');
        display: block;
        height: 27px;
        width: 27px;
        position: absolute;
        top: -6px;
        left: -33px;
      }
    }
  }

  &__footer {
    padding-top: 40px;
  }
  &__all {
    width: 215px;
    height: 27px;
    border-radius: 10px;
    background-color: #5c2564;
    text-align: center;
    display: block;
    color: #ffffff;
    line-height: 27px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 auto;
  }
}

.services {
  padding-top: 60px;
  padding-bottom: 60px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
  }

  &-item {
    width: 20%;
    padding: 4px;
    &__inner {
      padding: 10px 16px;
      background-color: #efece6;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 95% 95%;
      padding-bottom: 140px;
    }

    &__txt {
      font-weight: bold;
      font-size: 11px;
      text-align: justify;
    }

    &__body {
      padding-top: 17px;
    }

    &__title {

      &-text {
        font-family: Oswald-Extra-Light;
        color: #3c0034;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

}

.printing-services-in-moscow {
  padding-top: 36px;
  padding-bottom: 78px;
  &__row {
    display: flex;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__text {
  
    p {
      color: #040707;
      margin-bottom: 27px;
      font-weight: bold;
      text-align: justify;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &_mt {
      margin-top: 7px;
      padding-left: 11px;
    }
  }

  &__body {
    padding-top: 28px;
  }

  img {
    object-fit: cover;
    margin-right: 10px;
  }
}

.work-examples {
  padding-top: 34px;
  padding-bottom: 30px;

  &__body {
    padding-top: 50px;
    display: flex;
  }

  &__empty {
    width: 7%;
  }
}
.work-examples-slider {
  width: 100%;

  &__container {
    width: 86%;
  }
}
.work-examples-slide {
  width: 100%;
  &__img {
    background-color: #efece6;
    border-left:2px solid #fff;
    border-right:2px solid #fff;
    height: 194px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 auto;
    }
  }

  &__body {
    padding: 10px;
    padding-bottom: 30px;
    border-left:2px solid #c293bf;
    border-right:2px solid #c293bf;
    height: 50px;

    &_no-left-border {
      border-left: none;
    }

    &_no-right-border {
      border-right: none;
    }
  }

  &__name {
    color: #3c0034;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

#work-examples-prev,
#work-examples-next {
  background-image: url(../img/icons/our-clients-arrow.png);
  width: 28px;
  height: 45px;
  background-color: transparent;
}

#work-examples-next {
  transform: rotate(180deg);
}

.about-us {
  padding-top: 40px;
  &__inner {
    padding-top: 20px;
    display: flex;
  }

  &__content {
    width: 63%;
    padding-right: 15px;
    p {
      color: #040707;
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 18px;
      text-align: justify;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    width: 37%;
  }
}

.differences-from-other {
  padding-top: 24px;
  padding-bottom: 37px;
  &__content {
    padding-top: 26px;

    p {
      margin-bottom: 28px;
      font-weight: bold;
      color: #040707;
      .bold {
        font-family: 'AvenirNextCyr-Bold';
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


.price-list {
  padding-top: 33px;
  padding-bottom: 64px;
  &__footer-text {
    margin-top: 15px;
    color: #3c0034;
    font-size: 18px;
    font-weight: bold;
    font-weight: 400;
    letter-spacing: -0.23px;
    text-align: center;
  }

  &__footer-link {
    font-weight: bold;
    color: #3c0034;
    font-family: AvenirNextCyr-Bold;
  }
  
}

.price-list-tabel {
  padding-top: 20px;
  border: unset;
  border-color: unset;
  margin-bottom: 0;
  vertical-align: unset;
  border-collapse: unset;
  border-spacing: 5px;

  &__blue-block {
    background-color: #c293bf !important;
    text-align: center;
    font-weight: bold;
  }

  thead {
    td {
      padding: 18px;
      background-color: #3c0034;
      color: #fff;
      text-align: center;
      vertical-align: text-bottom;
      width: 16.666666%;
    }
  }

  tbody {
    td {
      padding: 18px 5px;
      background-color: #efece6;
      font-size: 20px;
      color: #3c0034;
      border-width:unset;
      border:unset;
      vertical-align: unset;
      border-collapse:unset;
      letter-spacing: -1px;
      text-align: center;
      width: 16.666666%;
    }
  }

  

  &__head-title {
    display: block;
    color: #fefefe;
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__head-text {
    color: #fefefe;
    font-size: 14px;
    font-weight: bold;
    padding-top: 25px;
    display: block;
  }
}

.advertising-commercial-printing {
  padding-top: 38px;
}

.advertising-commercial-printing-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  margin: 0 -18px;
}

.advertising-commercial-printing-item {
  width: 50%;
  padding: 18px;

  &__img {
    height: 170px;
    background-color: #efece6;
    background-position: center;
    background-size: cover;
  }
  &__name {
    background-color: #3c0034;
    text-align: center;
    padding: 28px 0;
    &-text {
      width: 309px;
      height: 21px;
      color: #ffffff;
      font-size: 23px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.advertising-commercial-printing {
  padding-bottom: 28px;
}

.stages-of-work {
  padding-top: 42px;
  padding-bottom: 45px;
}
.stages-of-work-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}

.stages-of-work-item {
  width: 20%;
  //background-color: #f7f4ed;
  padding: 16px 0 4px;
  height: 190px;
  //border-left: 3px solid #fff;
  //border-right: 3px solid #fff;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 17px;
  margin-left: -14px;
  overflow: hidden;
  background-size: cover;
  background-position: center;

  &_start {
    background-image: url('../img/poligon/middle.png');
    &:after {
      content:'';
      
      display: block;
      position: absolute;
      width: 30px;
      height: calc(100% - 6px);
      width: 40px;
      top: 3px;
      left: 0;
      background: #f8f4ed;
    
    }
  }

  &_middle {
    background-image: url('../img/poligon/middle.png');
  }

  &_end {
    background-image: url('../img/poligon/middle.png');

    &:after {
      content:'';
      display: block;
      position: absolute;
      width: 30px;
      height: calc(100% - 6px);
      width: 40px;
      top: 3px;
      right: -24px;
      background: #fff;
    
    }
  }

  &__img {
    text-align: center;
    
    img {
      height: 125px;
      position: relative;
      z-index: 55;
    }

    &_small {
     img {
      height: 115px;
     }
    }
  }

  &__body {
    text-align: center;
    padding-top: 12px;

    &_np {
      padding-top: 0;
    }
  }

  &__name {
    color: #040707;
    font-family: 'AvenirNextCyr-Demi';
    font-size: 14px;
    font-weight: bold;
    line-height: 12px;
    text-transform: uppercase;
    display: flex;
    line-height: 14px;
    justify-content: center;
  }

  &__sub-text {
  color: #5c2564;
  font-family: 'AvenirNextCyr-Demi';
  font-size: 14px;
  font-weight: bold;
  line-height: 14.04px;
  display: block;
  }

  &_to-arrow {
  
    &:after {
      // content: '';
      // display: block;
      // position: absolute;
      // width: 40px;
      // height: 40px;
      // background: #f7f4ed;
      // top: 50%;
      // transform: translateY(-50%) rotate(134deg);
      // z-index: 55;
      // border: 6px solid #f7f4ed;
      // border-bottom:none;
      // border-right: none;
      // border-top-left-radius: 5px;
      // //border-radius:5px;
      // box-shadow: 0 0 0 6px #fff;
      //right: -10px;
    }

    // &:after {

    //   border-color: transparent transparent transparent #f7f4ed;
    //   border-width: 22px;
    // }

    // &:before {
    //   border-color: transparent transparent transparent #fff;
    //   border-width: 28px;
    // }
  }
}

.payment-methods {
  padding-top: 36px;
  padding-bottom: 60px;
  &__list {
    display: flex;
    flex-direction: column;
    padding-right: 37px;
  }
  &__link {
    display: inline-block;
    width: 166px;
    height: 21px;
    border-radius: 10px;
    background-color: #c293bf;
    display: inline-block;
    color: #ffffff;
    font-family: AvenirNextCyr-Demi;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    line-height: 21px;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 12px;
    letter-spacing: -.5px;

    &.active {
      background-color: #3c0034;
    }

    &:last-child {
      margin-bottom: 0;
    } 
  }

  &__inner {
    display: flex;
    align-items: center;
    padding-top: 21px;
  }

  &__content {
    //padding-left: ;
    max-width: 815px;
    p {
      color: #040707;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
.delivery-methods {
  padding-top: 40px;
  padding-bottom: 68px;
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-top: 31px;
  }
  &__left-block {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__right-block {
    width: 50%;
    padding-left: 86px;
  }

  &__ps {
      width: 428px;
      padding-top: 24px;
      padding-right: 112px;
    p {
      color: #3c0034;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      &.bold {
        font-weight: bold;
      }
    }
  }
}
.delivery-methods-table {
  border-spacing: 3px;
  border-collapse: unset;
  table-layout: unset;
  border: unset;
  vertical-align: unset;
  width: unset;
  margin-bottom: unset;
  
  thead {
    
    td {
      
    }
  }

  td {
    text-align: center;
    vertical-align: middle;
    color: #3c0034;
    padding: 10px;
    line-height: 18px;
    border-width:unset;
    border-collapse:unset;
    border: unset;
    //background-color: #c69bc3;
    //color: #3c0034;
  }

  tbody {
    td {
      background-color: #f7f4ed;
    }
  }

  .purple {
    background-color: #c69bc3;
    font-weight: bold;
    text-transform: uppercase;
  }

  & .bold {
    font-weight: bold;
  }

}

.delivery-methods-content {
  p {
    margin-bottom: 40px;
    font-weight: bold;
    color: #000000;
    &:last-child {
      margin-bottom: 0;
    }
    
  }

  a {
    text-decoration: underline;
  }

  &__title {
    text-transform: uppercase;
  }

  .bold {
    font-weight: bold;
  }

  .purple {
    color: #3c0034;
  }

  .pink {
    color: #c293bf;
  }
}

.feedback {
  padding-top: 35px;
  &__content {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    background-color: #efece6;
    margin-top: 26px;

    .center-nav-phone-group__number {
      color: #59275b;
    }
    .center-nav-phone-group__back-call {
      color: #c293bf;
    }
  }
}

.feedback-form {

  &__input-container {
    display: flex;
  }

  input {
    width: 538px;
    padding: 12px 20px;
    font-size: 16px;
    height: 38px;
    background-color: #ffffff;
    border: none;
    outline: none;

    &::placeholder {
      color: #3c0034;
      font-family: AvenirNextCyr-Regular;
      font-size: 16px;
      font-weight: bold;
      line-height: 14px;
    }
  }
  &__btn {
    width: 43px;
    height: 38px;
    background-color: #c293bf;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.presentation-of-the-company {
  padding-top: 18px;
  padding-bottom: 43px;
  &__download-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__download-block {
    margin-top: 6px;
  }

  &__download {
    color: #3c0034;
    font-size: 14px;
    font-weight: bold;
    line-height: 13.95px;
    text-decoration: underline;
    letter-spacing: -0.42px;
    margin-top: 5px;
  }
}

.footer {
  background-color: #313131;
  padding-top: 48px;
  padding-bottom: 24px;

  &__inner {
    display: flex;
    justify-content: space-between;
  }
}

.footer-logo-block {

  &__info {
    margin-left: 46px;
  }
  &__info-text {
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    line-height: 15.95px;
    letter-spacing: 0.06px;
  }

  &__title {
    color: #c293bf;
    font-size: 12px;
    font-weight: 600;
    line-height: 29.79px;
    letter-spacing: -0.14px;
  }
}

.footer-contact-block {
  padding-left: 128px;

  &__title {
    color: #c293bf;
    font-size: 12px;
    font-weight: 600;
    line-height: 29.79px;
    letter-spacing: -0.14px;
  }

  .center-nav-metro {
    margin-left: -27px;
    padding-left: 0;
    padding-top: 14px;
  }

  .center-nav-phone-group {
    margin-left: -27px;
    padding-left: 0;
    padding-top: 14px;
  }

  .center-nav-metro__name {
    color: #f5f5f5;
  }

  .center-nav-metro__address {
    color: #c293bf;
  }

  .center-nav-phone-group__number {
    color: #feffff;
  }

  .center-nav-phone-group__back-call {
    color: #c293bf;
  }
}

.footer-social-list {
  display: flex;
  padding-top: 23px;
  &__item {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-work-time {
  margin-top: 33px;
  color: #c293bf;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.95px;
  letter-spacing: 0.07px;
}

.footer-menu-block {
  padding-left: 112px;
}

.footer-menu-block-list {

  &__item {
    a {
      color: #c293bf;
      font-size: 12px;
      font-weight: 600;
      line-height: 29.79px;
      letter-spacing: -0.36px;
      padding-left: 10px;
      white-space: nowrap;
    }

    

    &_arrow-right {
      background-image: url('../img/icons/arrow-left.svg');
      background-repeat: no-repeat;
      background-size: 8px;
      background-position-y: center;
      padding-left: 10px;
    }
  }

  &__title {
    color: #c293bf;
    font-size: 14px;
    font-weight: 600;
    line-height: 29.79px;
    letter-spacing: -0.36px;
    white-space: normal;
  }

}

.footer-payment-list {
  display: flex;

  li {
    margin-right: 13px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-payment-block {
  padding-left: 80px;

  &__nav-list {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a {
      color: #c293bf;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.15px;
    }
  }

  &__qr {
    margin-top: 17px;
  }
}

.footer-payment-form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  input {
    padding: 10px;
    background: none;
    border:1px solid #59275b;
    margin-top: 6px;
    &::placeholder {
      color: #c293bf;
    }
  }

  button {
  
    width: 74px;
    height: 24px;
    background-color: #59275b;

    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.09px;
    text-transform: uppercase;
    letter-spacing: -0.23px;
    margin-top: 6px;
  }
}

.footer {
  border-bottom: 2px solid #c293bf;
}

.footer-post {
  background-color: #313131;
  padding-bottom: 37px;
  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__txt {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    line-height: 29.79px;
    letter-spacing: -0.16px;
  }
}

.mobile-menu {
  padding: 20px;
  position: fixed;
  background-color: #3c0034;
  top: 0;
  z-index: 555;
  height: 100vh;
  width: 100%;
  transform: translateX(-100%);
  transition: .3s ease;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.open {
    height: 100vh;
    overflow-y: auto;
    transform: translateX(0%);
  }

  &__btn-block {
    line-height: 0;

    .open-menu-btn {
      line-height: 0;
    }
  }

  &__inner {
    
    
  }

  &__top {
    display: flex;
    justify-content: flex-end;
  }

  .top-nav {

    &__link {
      color: #fff;
      line-height: 2.4;
    }
    &__list {
      flex-direction: column;
    }
  }

  &__header {
    padding: 10px;
    background-color: #5c2564;
  }

}

.contact-menu-btn {
  background: none;
}

.open-menu-btn {
  background: none;
}

.mobile-menu-contacts {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 555;
  background: #fff;
  transform: translateX(-100%);
  transition: .3s;
  &.open {
    transform: translateX(0);
  }
}

.mobile-menu-contacts {
  padding: 20px;
  .center-nav-button-group {
    &__btn {
      margin-top: 10px;
    }
  }

  .center-nav-metro__address, 
  .center-nav-phone-group__back-call, 
  .center-nav-phone-group__number {
    font-size: 13px;
  }

  .center-nav-metro {

    &__list {
      padding-bottom: 20px;
    }

    
  }

  .center-nav-button-group__btn_w160,
  .center-nav-button-group__btn {
    width: 200px;
  }
}

.mobile-menu__header {
  display: none;
}

#home-slide__prev-arrow,
#home-slide__next-arrow {
  background: none;
  background-image: url('../img/icons/home-slider-arrow.png');
  background-size: cover;
  width: 57px;
  height: 94px;
  position: absolute;
  top: 40%;
  z-index: 1;
}

#home-slide__prev-arrow {
  left: 40px;
}

#home-slide__next-arrow {
  right: 40px;
  transform: rotate(180deg);
}


.attach-file-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  background-image: url('../img/icons/file.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  top: 3px;
  margin-right: 3px;
}

.remove-files-attach-files {
    
    line-height: 13px;
    text-align: center;
    border: 1px solid #3c0034;
    color: #3c0034;
    border-radius: 50%;
    display: block;
    display: inline-block;
    margin-right: 4px;
    cursor: pointer;
    transition:.2s ease;
    
    &:hover {
      color:#fff;
      background: #3c0034;
    }
}
.modal {
  width: 500px;
  padding-bottom: 30px;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 600;
  display: none;

  &_map {
    width: 770px;
  }

  &__btn {
    padding: 10px;
    display: inline-block;
    width: 230px;
    font-size: 16px;
    color: #fff;
    font-family: AvenirNextCyr-Regular;
    background-color: #3c0034;
    font-weight: bold;
    border-radius: 4px;
    text-transform: uppercase;
  }

  &__head {
    background-color: #fcfcfc;
    padding: 15px 30px 0;
    color: #3c0034;
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
  }

  &__attach-btn {
    padding: 0px 0 0px 29px;
    width: 112px;
    background-image: url(../img/common/upload.svg);
    background-position: left center;
    background-repeat: no-repeat;
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    color: #000;
  }

  &__attach-input {
    display: none;
  }

  &__attach-list {

    li {
      font-size: 12px;
      white-space: nowrap;
      width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-bottom: 3px;
    }
  }

  &__row {
    padding: 10px 0;
  
    &_attached {
      display: flex;
      justify-content: space-between;
      align-items:flex-start;
    }
  }

  &__input-label {
    position: absolute;
    left: 10px;
    top: 3px;
    font-size: 14px;
    color: #383737;
  }

  &__input-container {
    position: relative;
   
  }

  &__input {
    width: 100%;
    padding: 26px 10px 10px;
    font-size: 18px;
    background-color: #fff;
    border: none;
    outline: none;
    display: block;
    margin: 0 auto;
    color: #3c0034;
    font-weight: bold;
    font-family: AvenirNextCyr-Regular;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
    border-radius: 5px;
    &::placeholder {
      color: #3c003452;
      font-family: AvenirNextCyr-Regular;
      font-size: 18px;
      font-weight: bold;
      line-height: 14px;
    }
  }

  &__body {
    background-color: #fcfcfc;
    padding: 20px 30px 30px;
  }

  &__close-btn {
    background: none;
    position: absolute;
    right: 20px;
    top: 7px;
  }

  &__success-icon {
    width: 100px;
    margin: 0 auto;
  }

  &__success-txt {
    text-align: center;
    padding-top: 10px;
    &-value {
      font-weight: bold;
    }
  }

  &_pdp {
    font-size: 14px;
  }
}

#bgx {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.8);
  z-index: 559;
  display: none;
}

.tab-content {
  display: none;

  &.active {
    display: block;
    background: transparent;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  font-family: AvenirNextCyr-Regular;
  color: #000;
  font-size: 14px;
  & a{
    text-decoration: underline;
    color: #000;
    margin-left: 3px;
  }
  &__input:checked + .checkbox__box {
    background:#3c0034;
  }

  &__input {
     display: none;
  }
  
  &__box {
    cursor: pointer;
    display: inline-block;
    height: 14px;
    width: 14px;
    border: 1px solid #3c0034;
    border-radius: 2px;
    position: relative;
    margin-right: 5px;
    &:after {
      content: '';
      display: block;
      height: 9px;
      width: 5px;
      border:1px solid #fff;
      position: absolute;
      transform: rotate(217deg);
      top: -1px;
      left: 3px;
      border-right: none;
      border-bottom: none;
    }
  }


}

.mobile-open-sub-menu {
  display: none;
}

@media (max-width:1350px) {
  .container {
    padding: 0 15px;
    width: 1150px;
  }

  .center-nav-button-group,
  .center-nav-metro,
  .center-nav-phone-group {
    padding-left: 0;
  }

  .price-list-tabel__scroller {
    overflow-x: scroll;
  }

  .services-item {
    width: 33.33333s;
  }
}

@media (max-width:1200px) {
  .container {
    padding: 0px;
    width: 98%;
  }
  .top-nav__link {
    font-size: 10px;
  }

  .center-nav-phone-group__number {
    font-size: 13px;
  }

  .center-nav-metro__address,
  .center-nav-phone-group__number,
  .center-nav-phone-group__back-call {
    font-size: 10px;
  }

  .center-nav-button-group__btn {
    font-size: 10px;
    width: 100px;
  }

  .center-nav-button-group__btn_w160 {
    width: 130px;
  }

  .advertising-commercial-printing-list {
    margin: 0;
  }
  // .header {
  //   display: none;
  // }
}

@media (max-width:900px) {
  .container {
    padding: 0px;
    width: 98%;
  }
  .mobile-menu__header {
    display: flex;
  }
  .top-nav__link {
    font-size: 14px;
  }

  .header {
    display: none;
  }

  .popular-typography {
    padding-top: 20px;
  }
  .popular-typography-item {
    width: 33.3333%;
  }

  .advantage-typography {
    padding-top: 30px;
    padding-bottom: 20px;
    &__ps {
      margin-top: 20px;
    }
  }

  .advantage-typography__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .advantage-typography-item {
    width: 33.3333%;
  }

  .reviews-list {
    flex-wrap: wrap;
    padding-top: 0px;
  }

  .services {
    padding-top: 30px;
  }

  .reviews-item {
    width: 50%;
    padding-top: 30px;
  }

  .printing-services-in-moscow__row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .printing-services-in-moscow__text {
    width: 100%;
    padding-left: 0;
    padding-top: 20px;
    margin-top: 0;
  }

  .printing-services-in-moscow__row-mt {
    margin-top: 30px;
  }

  .services__list {
    padding-top: 20px;
  }

  .services-item {
    width: 50%;
  }

  .about-us__inner {
    flex-direction: column;
  }

  .about-us__content {
    width: 100%;
    padding-right: 0;
    padding-top: 20px;
  }
  
  .about-us__image {
    width: 100%;
    order: -1;
    text-align: center;
  }

  .differences-from-other {
    padding-bottom: 20px;
  }

  .advertising-commercial-printing-list {
    padding-top: 0;
    margin: 0;
  }

  .advertising-commercial-printing-item {
    &__name-text {
      font-size: 17px;
    }
  }

  .feedback {

    &__content {
      flex-direction: column;
    }

    .home-page-slider {

      &__img {
        padding: 0 40px;
      }
    }

    
  }

  .delivery-methods {
    
    &-table {
      margin: 0 auto;
    }

    &__inner {
      flex-direction: column;
    }

    &__left-block {
      width: 100%;
    }

    &__right-block {
      width: 100%;
      padding-left: 0px;
    }

    &__ps {
      width: 100%;
      padding-bottom: 30px;
      p {
        margin-bottom: 10px;
      }
    }
    &-content {

      p {
        margin-bottom: 10px;
      }
    }

  }

  .footer {
    padding-top: 15px;
    &__inner {
      flex-wrap: wrap;
    }
  }

  .footer-menu-block,
  .footer-contact-block {
    padding: 0;
  }

  .footer-logo-block,
  .footer-contact-block {
    width: 50%;
    padding-bottom: 30px;
  }

  .price-list-tabel {
    &__scroller {
      overflow-x: scroll;
    }
  }

  .price-list-tabel {
   
    &__head-text {
      font-size: 15px;
    }

    tbody {
      td {
        padding: 10px 14px;
        background-color: #efece6;
        font-size: 15px;
        color: #3c0034;
      }
    } 
  }

  .price-list-tabel__head-title {
    font-size: 17px;
  }
  .stages-of-work-list {
    justify-content: center;
  }
  .stages-of-work-item {
    width: 33.3333%;
  }

  #home-slide__next-arrow, 
  #home-slide__prev-arrow {
      width: 30px;
      height: 50px;
  }
  
  .home-page-slider__img {
    padding: 0 40px;
  }
}

@media (max-width:670px) {

  .modal {
    width: 98%;
  }
  .container {
    padding: 0 10px;
  }
  .popular-typography-item {
    width: 50%;
  }

  .advantage-typography-item {
    width: 50%;
  }

  .reviews-item {
    width: 100%;
  }

  .services-item {
    width: 100%;
  }

  .stages-of-work-item {
    width: 50%;
    margin-left: 0;
    height: 170px;
    margin-top: 10px;

    & img {
      height: 100px;
    }
  }

  .payment-methods {
    &__inner {
      flex-direction: column;
    }

    &__list {
      width: 100%;
      padding-right: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__link {
      margin: 10px;
    }
  }

  .feedback-form input {
    width: 268px;
  }
  .printing-services-in-moscow__text {
    P {
      font-size: 13px;
    }
  }
  .about-us__content {
    p {
      font-size: 13px;
    }
  }
  .delivery-methods-table {
    &__scroller {
      overflow-x: scroll;
    }

    td {
      font-size: 12px;
    }

    .bold {
      font-size: 12px;
    }
  }

  .advertising-commercial-printing-item {
    width: 100%;
  }

  .popular-typography {
    &__body {
      justify-content: space-between;
    }
  }

  .popular-typography-item {
    width: 45%;
  }

  .printing-services-in-moscow {

    &__row {
      align-items: flex-start;
      justify-content: space-between;
    }

    img {
      margin-right: 0;
      width: 48%;

      &.mobile-full-width {
        width: 100%;
      }
    }
  }

  .stages-of-work-list {
    display: flex;
    justify-content: space-between;
  }

  .footer {

    &__inner {
      flex-direction: column;
    }
  }
  .footer-logo-block {
    width: 40%;

    &__info {
      margin-left: 0px;
    }
  }

  .footer {
    padding: 20px;
  }
  .footer-contact-block .center-nav-metro, 
  .footer-contact-block .center-nav-phone-group {
    margin-left: 0;
  }

  .footer-menu-block {
    display: none;
  }

  .footer-payment-block {
    padding-left: 0;
  }

  .footer-contact-block, 
  .footer-logo-block {
    width: 100%;
  }

  .footer-post {
    &__inner {
      flex-direction: column-reverse;
    }
  }

  .top-nav-sub {
    position: relative;
    top: 3px;
    visibility: visible;
    opacity: 1;
    display: none;
  }

  .top-nav {

    &__item {
     
    }
  }
  .mobile-open-sub-menu {
    display: block;
    height: 20px;
    width: 20px;
    background-image: url(../img/icons/arrow-left.svg);
    background-repeat: no-repeat;
    transform: rotate(180deg);
    background-color: transparent;
    float: right;
    position: relative;
    top: 7px;
  }

  .top-nav-sub {

    &__item {

      &:first-child {
        margin-top: 10px;
      }
    }
  }

  .modal_pdp.checkbox {
    display: unset;
  }
}

}